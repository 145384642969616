var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CInput", {
        staticClass: "mb-3 search",
        attrs: { type: "search", placeholder: "Search for restaurants..." },
        on: { input: _vm.searchRestaurants },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _vm.loading
        ? _c("CSpinner")
        : _vm.restaurants.length
        ? _c("CDataTable", {
            staticClass: "table-add-delivery-service",
            attrs: {
              striped: "",
              hover: "",
              items: _vm.restaurants,
              fields: _vm.fields,
              noItemsView: {
                noResults: "No filtering results are available!",
                noItems: "No restaurants found!",
              },
              "clickable-rows": "",
            },
            on: { "row-clicked": _vm.rowClicked },
            scopedSlots: _vm._u([
              {
                key: "select",
                fn: function ({ item }) {
                  return [
                    _c(
                      "td",
                      [
                        _c("CInputCheckbox", {
                          attrs: { checked: item._selected, custom: "" },
                          on: { "update:checked": () => _vm.check(item) },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "id",
                fn: function ({ item }) {
                  return [_c("td", [_vm._v(" #" + _vm._s(item?.id) + " ")])]
                },
              },
              {
                key: "restaurant_logo",
                fn: function ({ item }) {
                  return [
                    _c(
                      "td",
                      [
                        _c("CImg", {
                          staticClass: "c-avatar-img",
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: item.restaurant_logo_path,
                            placeholderColor: "lightgray",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "restaurant_name",
                fn: function ({ item }) {
                  return [
                    _c("td", [
                      _vm._v(" " + _vm._s(item.restaurant_name) + " "),
                      item.none_active
                        ? _c("span", [_vm._v("(NONE_ACTIVE!)")])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
              {
                key: "location",
                fn: function ({ item }) {
                  return [
                    _c("td", [
                      _c("strong", [_vm._v(_vm._s(item.restaurant_city))]),
                      _vm._v(" "),
                      _c("small", [_vm._v(_vm._s(item.restaurant_postcode))]),
                    ]),
                  ]
                },
              },
            ]),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }